const LoginPage = () =>
  import(/* webpackChunkName: "LoginPage" */ '@/pages/Login/Login.vue')
const NotFoundPage = () =>
  import(/* webpackChunkName: "NotFoundPage" */ '@/pages/NotFound/NotFound.vue')
import * as _permissions from '../helpers/ability/permissions'
const routePage = route => {
  const { page = 1 } = route.params

  return {
    page: Math.max(1, Number(page)),
  }
}
export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'GrowthPage' },
    meta: {
      requiresPermission: _permissions.growth,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundPage,
  },

  {
    path: '/',
    name: 'GrowthPage',

    component: () => import('@/views/Growth/Growth.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'reviews',
        name: 'ReviewsPage',
        redirect: { name: 'FollowUpPage' },
        component: () => import('@/pages/Reviews/Reviews.vue'),
        meta: {
          title: 'pageNavigation.growth.routes.reviews',
        },

        children: [
          {
            path: 'report',
            name: 'ReportPage',
            component: () =>
              import('@/pages/Reviews/Children/Report/Report.vue'),
            redirect: { name: 'MyReportPage' },
            meta: {
              title: 'pageNavigation.growth.routes.report',
            },

            children: [
              {
                path: 'people',
                name: 'PeopleReport',
                component: () =>
                  import(
                    '@/pages/Reviews/Children/Report/Children/PeopleReport/PeopleReport.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.growth_assessments_report,
                },
              },
              {
                path: 'group',
                name: 'GroupReport',
                component: () =>
                  import(
                    '@/pages/Reviews/Children/Report/Children/GroupReport/GroupReport.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.growth_assessments_report,
                },
              },
              {
                path: 'me',
                name: 'MyReportPage',
                component: () =>
                  import(
                    '@/pages/Reviews/Children/Report/Children/MyReportPage/MyReportPage.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.profile_growth_report,
                  title: 'pageNavigation.growth.routes.myReport',
                },
              },
            ],
          },

          {
            path: 'comparison',
            name: 'ComparisonPage',
            component: () =>
              import('@/pages/Reviews/Children/Comparison/Comparison.vue'),
            meta: {
              requiresPermission: _permissions.disabled,
              title: 'pageNavigation.growth.routes.comparison',
            },
          },
          {
            path: 'invitations',
            name: 'InvitationsDefault',
            component: () =>
              import('@/pages/Reviews/Children/Invitations/Invitations.vue'),
            redirect: { name: 'InvitationsPage' },
            meta: {
              title: 'pageNavigation.growth.routes.invitations',
            },

            children: [
              {
                path: 'people',
                name: 'InvitationsPage',
                component: () =>
                  import(
                    '@/pages/Reviews/Children/Invitations/Children/InvitationsPage/InvitationsPage.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.growth_assessments_invites,
                },
              },
              {
                path: 'group',
                name: 'InvitationsGroup',
                component: () =>
                  import(
                    '@/pages/Reviews/Children/Invitations/Children/InvitationsGroup/InvitationsGroup.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.growth_assessments_invites,
                },
              },
              {
                path: 'me',
                name: 'MyInvitations',
                component: () =>
                  import(
                    '@/pages/Reviews/Children/Invitations/Children/MyInvitations/MyInvitations.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.profile_growth_invites,
                  title: 'pageNavigation.growth.routes.myInvitations',
                },
              },
            ],
          },
          {
            path: 'participation',
            name: 'ParticipationPage',
            component: () =>
              import(
                '@/pages/Reviews/Children/Participation/Participation.vue'
              ),
            meta: {
              requiresPermission: _permissions.disabled,
              title: 'pageNavigation.growth.routes.participation',
            },
          },
          {
            path: 'follow-up',
            name: 'FollowUpPage',
            component: () =>
              import('@/pages/Reviews/Children/FollowUp/FollowUp.vue'),
            meta: {
              requiresPermission: _permissions.growth_assessments_followup,
              title: 'pageNavigation.growth.routes.followup',
            },
          },
        ],
      },
      {
        path: 'one-on-one',
        name: 'OneOnOnePage',
        redirect: { name: 'NextMeetingsPage' },
        component: () => import('@/pages/OneOnOne/OneOnOne.vue'),
        meta: {
          requiresPermission: _permissions.growth_1on1,
          title: 'pageNavigation.growth.routes.oneOnOne',
        },
        children: [
          {
            path: 'next-meetings',
            name: 'NextMeetingsPage',
            component: () =>
              import('@/pages/OneOnOne/Children/NextMeetings/NextMeetings.vue'),
            meta: {
              requiresPermission: _permissions.growth_1on1_next_meetings,
              title: 'pageNavigation.growth.routes.nextMeetings',
            },
          },
          {
            path: 'last-meetings',
            name: 'LastMeetingsPage',
            component: () =>
              import('@/pages/OneOnOne/Children/LastMeetings/LastMeetings.vue'),
            meta: {
              requiresPermission: _permissions.growth_1on1_previous_meetings,
              title: 'pageNavigation.growth.routes.lastMeetings',
            },
          },
        ],
      },
      {
        path: 'compliments',
        name: 'ComplimentsPage',
        redirect: { name: 'ReceivedComplimentsPage' },
        component: () => import('@/pages/Compliments/Compliments.vue'),
        meta: {
          requiresPermission: _permissions.disabled,
          title: 'pageNavigation.growth.routes.compliments',
        },

        children: [
          {
            path: 'received',
            name: 'ReceivedComplimentsPage',
            component: () =>
              import(
                '@/pages/Compliments/Children/ReceivedCompliments/ReceivedCompliments.vue'
              ),
            meta: {
              requiresPermission: _permissions.disabled,
              title: 'pageNavigation.growth.routes.receivedCompliments',
            },
          },
          {
            path: 'sent',
            name: 'SentComplimentsPage',
            component: () =>
              import(
                '@/pages/Compliments/Children/SentCompliments/SentCompliments.vue'
              ),
            meta: {
              requiresPermission: _permissions.disabled,
              title: 'pageNavigation.growth.routes.sentCompliments',
            },
          },
        ],
      },
      {
        path: 'pdis',
        name: 'PDIsPage',
        redirect: { name: 'MyPDIsPage' },
        component: () => import('@/pages/PDIs/PDIs.vue'),
        meta: {
          title: 'pageNavigation.growth.routes.pdis',
        },

        children: [
          {
            path: 'my-pdis',
            name: 'MyPDIsPage',
            component: () =>
              import('@/pages/PDIs/Childrens/MyPDIs/MyPDIsPage.vue'),
            meta: {
              requiresPermission:
                _permissions.profile_growth_personal_development_plans,
              title: 'pageNavigation.growth.routes.myPdis',
            },
            children: [
              {
                path: ':action/:id?',
                name: 'ActionMyPDIsPage',
              },
            ],
          },
          {
            path: 'group-pdis',
            name: 'PDIsGroupsPage',
            component: () =>
              import('@/pages/PDIs/Childrens/PDIsGroups/PDIsGroupsPage.vue'),
            meta: {
              requiresPermission:
                _permissions.growth_personal_development_plans,
              title: 'pageNavigation.growth.routes.groupPdis',
            },
            children: [
              {
                path: ':action/:id?',
                name: 'ActionPDIsGroupsPage',
              },
            ],
          },
        ],
      },
      {
        path: 'feedbacks',
        name: 'FeedbacksPage',
        redirect: { name: 'MyFeedbacksChatPage' },
        component: () => import('@/pages/Feedbacks/Feedback.vue'),
        meta: {
          requiresPermission: _permissions.profile_feedback,
          title: 'pageNavigation.growth.routes.feedbacks',
        },
        children: [
          {
            path: 'my',
            name: 'MyFeedbacksPage',
            redirect: { name: 'MyFeedbacksChatPage' },
            component: () =>
              import('@/pages/Feedbacks/Children/MyFeedbacks/MyFeedbacks.vue'),
            children: [
              {
                path: ':feedbackID?',
                name: 'MyFeedbacksChatPage',
                component: () =>
                  import(
                    '@/pages/Feedbacks/Children/MyFeedbacks/Children/MyFeedbacksChat/MyFeedbacksChat.vue'
                  ),
                meta: {
                  title: 'pageNavigation.growth.routes.receivedFeedbacks',
                },
                props: true,
              },
              {
                path: 'list/:page?',
                name: 'MyFeedbacksListPage',
                component: () =>
                  import(
                    '@/pages/Feedbacks/Children/MyFeedbacks/Children/MyFeedbacksList/MyFeedbacksList.vue'
                  ),
                meta: {
                  title: 'pageNavigation.growth.routes.receivedFeedbacks',
                },
                props: routePage,
              },
            ],
          },
          {
            path: 'requested',
            name: 'RequestedFeedbacksPage',
            redirect: { name: 'RequestedFeedbacksChatPage' },
            component: () =>
              import(
                '@/pages/Feedbacks/Children/RequestedFeedbacks/RequestedFeedbacks.vue'
              ),
            children: [
              {
                path: ':feedbackID?',
                name: 'RequestedFeedbacksChatPage',
                component: () =>
                  import(
                    '@/pages/Feedbacks/Children/RequestedFeedbacks/Children/RequestedFeedbacksChat/RequestedFeedbacksChat.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.profile_feedback_requested,
                  title: 'pageNavigation.growth.routes.requestedFeedbacks',
                },
                props: true,
              },
              {
                path: 'list/:page?',
                name: 'RequestedFeedbacksListPage',
                component: () =>
                  import(
                    '@/pages/Feedbacks/Children/RequestedFeedbacks/Children/RequestedFeedbacksList/RequestedFeedbacksList.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.profile_feedback_requested,
                  title: 'pageNavigation.growth.routes.requestedFeedbacks',
                },
                props: routePage,
              },
            ],
          },
          {
            path: 'group',
            name: 'GroupFeedbacksPage',
            redirect: { name: 'GroupFeedbacksChatPage' },
            component: () =>
              import(
                '@/pages/Feedbacks/Children/GroupFeedbacks/GroupFeedbacks.vue'
              ),
            children: [
              {
                path: 'list/:page?',
                name: 'GroupFeedbacksListPage',
                component: () =>
                  import(
                    '@/pages/Feedbacks/Children/GroupFeedbacks/Children/GroupFeedbacksList/GroupFeedbacksList.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.growth_feedback_teams,
                  title: 'pageNavigation.growth.routes.groupFeedbacks',
                },
                props: routePage,
              },
              {
                path: ':feedbackID?',
                name: 'GroupFeedbacksChatPage',
                component: () =>
                  import(
                    '@/pages/Feedbacks/Children/GroupFeedbacks/Children/GroupFeedbacksChat/GroupFeedbacksChat.vue'
                  ),
                meta: {
                  requiresPermission: _permissions.growth_feedback_teams,
                  title: 'pageNavigation.growth.routes.groupFeedbacks',
                },
                props: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/assessment/:id',
    name: 'AssessmentPage',
    component: () => import('@/views/Assessment/Assessment.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageAssessment.title',
    },
  },
  {
    path: '/exporter/:code',
    name: 'ExporterPage',
    component: () => import('@/views/Exporter/Exporter.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageExporter.title',
    },
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    meta: {
      external: '/profile',
    },
    children: [
      {
        path: 'home',
        name: 'home',
        meta: {
          external: '/profile/home',
        },
      },
    ],
  },
]
